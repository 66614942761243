import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../../components/seo"
import Layout from "../../components/layout"

import { CoverMain } from "../../components/cover-main/cover-main"
import { HeadingColumn } from "../../components/heading/heading"
import { Glider } from "../../components/glider/glider"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const ProjectsByTypes = ({ data, lang }) => {
  let items = []

  for (let i = data.length - 1; i >= 0; i--) {
    let glideData = []
    data[i].projects.nodes.map(propertyItem => {
      if (lang === "ID" && propertyItem.translation) {
        glideData.push({
          image: propertyItem.featuredImage
            ? propertyItem.featuredImage.sourceUrl
            : "https://www.sinarmasland.com/static/sml-plaza-6a6e8ebe7eb4ad249e70d5149c18fcc0.png",
          propertyName: propertyItem.title || "Title",
          type: data[i].name,
          location:
            propertyItem.locations.nodes[0] !== undefined
              ? propertyItem.locations.nodes[0].name
              : null,
          description:
            lang === "EN"
              ? propertyItem?.excerpt
              : propertyItem?.translation?.excerpt || propertyItem?.excerpt,
          linkTo:
            (propertyItem.slug &&
              lang === "EN" 
              ? data[i].name.toLowerCase() === "kavling" ? `/doubledream/${data[i].name.toLowerCase()}` : data[i].name.toLowerCase() === "doubledream property" ? `/doubledream` : data[i].name.toLowerCase() === "doubledream faq" ? `/doubledream/faq` : `/development/${data[i].name.toLowerCase()}` 
              : data[i].name.toLowerCase() === "kavling" ? `/doubledream/${data[i].name.toLowerCase()}` : data[i].name.toLowerCase() === "doubledream property" ? `/doubledream` : data[i].name.toLowerCase() === "doubledream faq" ? `/doubledream/faq` : `/id/development/${data[i].name.toLowerCase()}/${propertyItem.slug}`
            ) || "/",
        })
      } else if (lang === "ID" && !propertyItem.translation) {
        glideData.push({
          image: propertyItem.featuredImage
            ? propertyItem.featuredImage.sourceUrl
            : "https://www.sinarmasland.com/static/sml-plaza-6a6e8ebe7eb4ad249e70d5149c18fcc0.png",
          propertyName: propertyItem.title || "Title",
          type: data[i].name,
          location:
            propertyItem.locations.nodes[0] !== undefined
              ? propertyItem.locations.nodes[0].name
              : null,
          description:
            lang === "EN"
              ? propertyItem?.excerpt
              : propertyItem?.translation?.excerpt || propertyItem?.excerpt,
          linkTo:
            (propertyItem.slug &&
              lang === "EN" 
              ? data[i].name.toLowerCase() === "kavling" ? `/doubledream/${data[i].name.toLowerCase()}` : data[i].name.toLowerCase() === "doubledream property" ? `/doubledream` : data[i].name.toLowerCase() === "doubledream faq" ? `/doubledream/faq` : `/development/${data[i].name.toLowerCase()}` 
              : data[i].name.toLowerCase() === "kavling" ? `/doubledream/${data[i].name.toLowerCase()}` : data[i].name.toLowerCase() === "doubledream property" ? `/doubledream` : data[i].name.toLowerCase() === "doubledream faq" ? `/doubledream/faq` : `/id/development/${data[i].name.toLowerCase()}/${propertyItem.slug}`
            ) || "/",
        })
      } else if (lang === "EN") {
        glideData.push({
          image: propertyItem.featuredImage
            ? propertyItem.featuredImage.sourceUrl
            : "https://www.sinarmasland.com/static/sml-plaza-6a6e8ebe7eb4ad249e70d5149c18fcc0.png",
          propertyName: propertyItem.title || "Title",
          type: data[i].name,
          location:
            propertyItem.locations.nodes[0] !== undefined
              ? propertyItem.locations.nodes[0].name
              : null,
          description:
            lang === "EN"
              ? propertyItem?.excerpt
              : propertyItem?.translation?.excerpt || propertyItem?.excerpt,
          linkTo:
            (propertyItem.slug &&
              lang === "EN" 
              ? data[i].name.toLowerCase() === "kavling" ? `/doubledream/${data[i].name.toLowerCase()}` : data[i].name.toLowerCase() === "doubledream property" ? `/doubledream` : data[i].name.toLowerCase() === "doubledream faq" ? `/doubledream/faq` : `/development/${data[i].name.toLowerCase()}` 
              : data[i].name.toLowerCase() === "kavling" ? `/doubledream/${data[i].name.toLowerCase()}` : data[i].name.toLowerCase() === "doubledream property" ? `/doubledream` : data[i].name.toLowerCase() === "doubledream faq" ? `/doubledream/faq` : `/id/development/${data[i].name.toLowerCase()}/${propertyItem.slug}`
            ) || "/",
        })
      }
      return 1
    })

    const propertyTypes = data[i]

    switch (propertyTypes?.slug) {
      case "township":
        items[0] = (
          <section
            key={`porjects-${i}`}
            className="py-main development-section"
          >
            <div className="container">
              <HeadingColumn
                className="pb-5"
                h2={
                  lang === "EN"
                    ? propertyTypes?.name
                    : propertyTypes?.translation?.name || propertyTypes?.name
                }
                text={[
                  lang === "EN"
                    ? propertyTypes?.description
                    : propertyTypes?.translation?.description ||
                    propertyTypes?.description,
                ]}
                btnText={
                  lang === "EN" ? "View All Projects" : "Lihat Semua Proyek"
                }
                btnLink={lang === "EN" ? `/development/${data[i].name.toLowerCase()}` : `/id/development/${data[i].name.toLowerCase()}`}
              />
            </div>
            <div className="container px-sm-down-0">
              <Glider id="development" data={glideData} />
            </div>
          </section>
        )
        break
      case "residential":
        items[1] = (
          <section
            key={`porjects-${i}`}
            className="py-main development-section"
          >
            <div className="container">
              <HeadingColumn
                className="pb-5"
                h2={
                  lang === "EN"
                    ? propertyTypes?.name
                    : propertyTypes?.translation?.name || propertyTypes?.name
                }
                text={[
                  lang === "EN"
                    ? propertyTypes?.description
                    : propertyTypes?.translation?.description ||
                    propertyTypes?.description,
                ]}
                btnText={
                  lang === "EN" ? "View All Projects" : "Lihat Semua Proyek"
                }
                btnLink={lang === "EN" ? `/development/${data[i].name.toLowerCase()}` : `/id/development/${data[i].name.toLowerCase()}`}
              />
            </div>
            <div className="container px-sm-down-0">
              <Glider id="development" data={glideData} />
            </div>
          </section>
        )
        break
      case "office":
        items[2] = (
          <section
            key={`porjects-${i}`}
            className="py-main development-section"
          >
            <div className="container">
              <HeadingColumn
                className="pb-5"
                h2={
                  lang === "EN"
                    ? propertyTypes?.name
                    : propertyTypes?.translation?.name || propertyTypes?.name
                }
                text={[
                  lang === "EN"
                    ? propertyTypes?.description
                    : propertyTypes?.translation?.description ||
                    propertyTypes?.description,
                ]}
                btnText={
                  lang === "EN" ? "View All Projects" : "Lihat Semua Proyek"
                }
                btnLink={lang === "EN" ? `/development/${data[i].name.toLowerCase()}` : `/id/development/${data[i].name.toLowerCase()}`}
              />
            </div>
            <div className="container px-sm-down-0">
              <Glider id="development" data={glideData} />
            </div>
          </section>
        )
        break
      case "retail":
        items[3] = (
          <section
            key={`porjects-${i}`}
            className="py-main development-section"
          >
            <div className="container">
              <HeadingColumn
                className="pb-5"
                h2={
                  lang === "EN"
                    ? propertyTypes?.name
                    : propertyTypes?.translation?.name || propertyTypes?.name
                }
                text={[
                  lang === "EN"
                    ? propertyTypes?.description
                    : propertyTypes?.translation?.description ||
                    propertyTypes?.description,
                ]}
                btnText={
                  lang === "EN" ? "View All Projects" : "Lihat Semua Proyek"
                }
                btnLink={lang === "EN" ? `/development/${data[i].name.toLowerCase()}` : `/id/development/${data[i].name.toLowerCase()}`}
              />
            </div>
            <div className="container px-sm-down-0">
              <Glider id="development" data={glideData} />
            </div>
          </section>
        )
        break
      case "hospitality":
        items[4] = (
          <section
            key={`porjects-${i}`}
            className="py-main development-section"
          >
            <div className="container">
              <HeadingColumn
                className="pb-5"
                h2={
                  lang === "EN"
                    ? propertyTypes?.name
                    : propertyTypes?.translation?.name || propertyTypes?.name
                }
                text={[
                  lang === "EN"
                    ? propertyTypes?.description
                    : propertyTypes?.translation?.description ||
                    propertyTypes?.description,
                ]}
                btnText={
                  lang === "EN" ? "View All Projects" : "Lihat Semua Proyek"
                }
                btnLink={lang === "EN" ? `/development/${data[i].name.toLowerCase()}` : `/id/development/${data[i].name.toLowerCase()}`}
              />
            </div>
            <div className="container px-sm-down-0">
              <Glider id="development" data={glideData} />
            </div>
          </section>
        )
        break
      case "commercial":
        items[5] = (
          <section
            key={`porjects-${i}`}
            className="py-main development-section"
          >
            <div className="container">
              <HeadingColumn
                className="pb-5"
                h2={
                  lang === "EN"
                    ? propertyTypes?.name
                    : propertyTypes?.translation?.name || propertyTypes?.name
                }
                text={[
                  lang === "EN"
                    ? propertyTypes?.description
                    : propertyTypes?.translation?.description ||
                    propertyTypes?.description,
                ]}
                btnText={
                  lang === "EN" ? "View All Projects" : "Lihat Semua Proyek"
                }
                btnLink={lang === "EN" ? `/development/${data[i].name.toLowerCase()}` : `/id/development/${data[i].name.toLowerCase()}`}
              />
            </div>
            <div className="container px-sm-down-0">
              <Glider id="development" data={glideData} />
            </div>
          </section>
        )
        break
      case "industrial":
        items[6] = (
          <section
            key={`porjects-${i}`}
            className="py-main development-section"
          >
            <div className="container">
              <HeadingColumn
                className="pb-5"
                h2={
                  lang === "EN"
                    ? propertyTypes?.name
                    : propertyTypes?.translation?.name || propertyTypes?.name
                }
                text={[
                  lang === "EN"
                    ? propertyTypes?.description
                    : propertyTypes?.translation?.description ||
                    propertyTypes?.description,
                ]}
                btnText={
                  lang === "EN" ? "View All Projects" : "Lihat Semua Proyek"
                }
                btnLink={lang === "EN" ? `/development/${data[i].name.toLowerCase()}` : `/id/development/${data[i].name.toLowerCase()}`}
              />
            </div>
            <div className="container px-sm-down-0">
              <Glider id="development" data={glideData} />
            </div>
          </section>
        )
        break
      default:
        items.push(
          data[i].name.toLowerCase() === "faq" ||
          data[i].name.toLowerCase() === "doubledream-faq" ||
          data[i].name.toLowerCase() === "doubledream-property" ||
          data[i].name.toLowerCase() === "smartmove-property" ||
          data[i].name.toLowerCase() === "smartmove-faq" ||
          data[i].name.toLowerCase() === "infiniteliving" ||
          data[i].name.toLowerCase() === "infiniteliving-faq" ||
          data[i].name.toLowerCase() === "kavling" ||
          data[i].name.toLowerCase() === "properti" ||
            data[i].name.toLowerCase() === "ecatalog" ? (
            <div></div>
          ) : (
            <div></div>
          )
        )
        break
    }
  }

  return items
}

const Developments = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(
        relativePath: { eq: "common/cover-image-development.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        page(id: "development", idType: URI) {
          title
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
          blocks {
            name
            ...CoreCoverBlock
          }
          translation(language: ID) {
            title
            seo {
              title
              metaDesc
              twitterTitle
              twitterDescription
              opengraphTitle
              opengraphDescription
            }
            blocks {
              name
              ...CoreCoverBlock
            }
          }
        }
        propertyTypes(where: { language: EN }, first:200) {
          nodes {
            name
            description
            slug
            translation(language: ID) {
              name
              description
              slug
            }
            projects {
              nodes {
                title
                slug
                excerpt
                featuredImage {
                  sourceUrl
                }
                locations {
                  nodes {
                    name
                  }
                }
                translation(language: ID) {
                  title
                  slug
                  excerpt
                  featuredImage {
                    sourceUrl
                  }
                  locations {
                    nodes {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const wordPress = data?.wordPress?.page
  const projects = data.wordPress.propertyTypes?.nodes

  const cover = wordPress?.blocks?.find(item => item.name === "core/cover")

  return (
    <Layout location={location} currentLocation={location.pathname} lang="EN">
      <SEO
        title={wordPress?.seo?.title || "Development"}
        fbTitle={wordPress?.seo?.title}
        twitterTitle={wordPress?.seo?.title}
        url={location?.href}
        img={cover?.attributes?.url}
        twitterImg={cover?.attributes?.url}
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      <CoverMain
        h1={wordPress?.title}
        img={cover?.attributes?.url}
        alt="Development"
        className="cover-md"
      />

      <ProjectsByTypes data={projects} lang="EN" />
    </Layout>
  )
}

export default Developments
